<template>
  <div id="editRedeemCode">
    <!-- 表单 -->
    <el-tabs v-model="activeName" @tab-click="tabClick">
      <!--------------------------------------------------------- 未兑换 ------------------------------------------------------>
      <el-tab-pane label="未兑换" name="1">
        <!-- 表格 -->
        <el-table
          :data="tableData"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
        >
          <el-table-column prop="code" label="兑换码" />
          <el-table-column label="创建时间">
            <template slot-scope="scope">
              {{ scope.row.c_time | formatTimeStamp }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope" v-if="tableData.length > 0">
              <el-button
                :disabled="!is_adder"
                size="mini"
                type="text"
                @click="poster(scope.row)"
              >
                海报分享
              </el-button>
              <el-divider v-if="is_adder" direction="vertical"></el-divider>
              <el-divider
                v-else
                direction="vertical"
                class="divider_color"
              ></el-divider>
              <el-button
                :disabled="!is_adder"
                size="mini"
                type="text"
                @click="send(scope.row.code)"
              >
                链接分享
              </el-button>
              <span v-if="showRemoveExchangecode">
                <el-divider v-if="is_adder" direction="vertical"></el-divider>
                <el-divider
                  v-else
                  direction="vertical"
                  class="divider_color"
                ></el-divider>
              </span>

              <el-button
                v-if="showRemoveExchangecode"
                :disabled="!is_adder"
                size="mini"
                type="text"
                @click="voidDHM(scope.row.redeem_code_id)"
              >
                作废
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--分页-->
        <pagination
          :currPage="formData.page"
          :total="total"
          :limit="15"
          @currentChange="pageCodeChange"
        ></pagination>
      </el-tab-pane>
      <!--------------------------------------------------------- 已兑换 ------------------------------------------------------>
      <el-tab-pane label="已兑换" name="2">
        <!-- 顶部搜索 -->
        <div class="header">
          <el-input
            class="w300 fr"
            :placeholder="
              formData.type == 1 ? '输入兑换人名称搜索' : '输入兑换码名称搜索'
            "
            v-model="formData.name"
            @input="changeForm"
          >
            <el-select
              class="w100"
              v-model="formData.type"
              slot="prepend"
              placeholder="请选择"
              @change="changeForm"
            >
              <el-option label="兑换人" :value="1"></el-option>
              <el-option label="兑换码" :value="2"></el-option>
            </el-select>
          </el-input>
        </div>
        <!-- 表格 -->
        <el-table
          :data="tableData"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
        >
          <el-table-column prop="code" label="兑换码" />
          <el-table-column label="创建时间">
            <template slot-scope="scope">
              {{ scope.row.c_time | formatTimeStamp }}
            </template>
          </el-table-column>
          <el-table-column label="兑换人">
            <template slot-scope="scope">
              <div
                class="cp student"
                @click="touserDetail(scope.row)"
                title="点击跳转至学员详情页面"
              >
                <img
                  :src="scope.row.uphoto"
                  alt=""
                  width="28"
                  height="28"
                  style="border-radius: 50%"
                />
                <div class="student_name ml10">
                  <span>{{ scope.row.uname }}</span>
                  <span
                    v-if="scope.row.student_remarks"
                    style="font-size: 12px"
                  >
                    {{ scope.row.student_remarks }}
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="兑换时间">
            <template slot-scope="scope">
              {{ scope.row.redeem_time | formatTimeStamp }}
            </template>
          </el-table-column>
        </el-table>
        <!--分页-->
        <pagination
          :currPage="formData.page"
          :total="total"
          :limit="15"
          @currentChange="pageCodeChange"
        ></pagination>
      </el-tab-pane>
      <!--------------------------------------------------------- 已作废 ------------------------------------------------------>
      <el-tab-pane label="已作废" name="3">
        <!-- 表格 -->
        <el-table
          :data="tableData"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
        >
          <el-table-column prop="code" label="兑换码" />
          <el-table-column label="创建时间">
            <template slot-scope="scope">
              {{ scope.row.c_time | formatTimeStamp }}
            </template>
          </el-table-column>
          <el-table-column label="作废时间">
            <template slot-scope="scope">
              {{ scope.row.invalid_time | formatTimeStamp }}
            </template>
          </el-table-column>
          <el-table-column prop="operating_name" label="操作人" />
        </el-table>
        <!--分页-->
        <pagination
          :currPage="formData.page"
          :total="total"
          :limit="15"
          @currentChange="pageCodeChange"
        ></pagination>
      </el-tab-pane>
    </el-tabs>
    <!--复制验证码弹窗-->
    <el-dialog
      class="send_verification"
      title="分享兑换码"
      :visible.sync="dialogIsShow_1"
    >
      <div class="box_info">
        <div class="right">
          <div class="info_left">{{ copyUrl }}</div>
          <div class="info_right" @click="copy" :data-clipboard-text="copyUrl">
            复制文案 + 链接
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 分享海报弹窗 -->
    <posterShare
      ref="posterShareref"
      :postval="postval"
      v-if="status"
      :dialogVisible.sync="status"
      :type="1"
    ></posterShare>
    <loading v-show="isLoading" leftPos="0"></loading>
    <!--End-->
  </div>
</template>
<script>
import posterShare from './posterShare'
import Clipboard from 'clipboard' //点击复制功能
import loading from '@/components/LoadGif'
export default {
  name: 'editRedeemCode',
  components: {
    loading,
    posterShare,
  },
  data() {
    return {
      status: false, //分享海报弹窗控制

      postval: {}, //被选中的分享海报的内容

      resultPic: '', //生成海报的图片地址

      isLoading: false,

      // 复制兑换码弹窗
      dialogIsShow_1: false,

      copyUrl: '',

      // tabs绑定值
      activeName: '1',

      // 表单数据
      formData: {
        name: '', // 搜索内容
        type: 1, // 搜索类型
        page: 1, // 页码
        status: '1', // 兑换码状态
        redeem_id: '', // 兑换码id
      },

      // 表格信息
      tableData: [],

      total: 0,

      is_adder: true, //是否是创建人
    }
  },
  mounted() {
    // 获取表格数据
    this.formData.redeem_id = this.$route.query.redeem_id
    this.getTableList()
    this.$root.$children[0].childPageOptions[
      this.$root.$children[0].childPageOptions.length - 1
    ].name = this.$route.query.name

    this.$parent.$parent.childPageOptions[0].prompt = ''
  },
  computed: {
    //作废兑换码
    showRemoveExchangecode() {
      return this.$store.state.roots.includes(148)
    },
  },
  methods: {
    touserDetail(row) {
      let url = this.$router.resolve({
        path: `/manageStudent/detail?uid=${row.uid}`,
      })
      this.$store.dispatch('open', url.href)
    },

    //海报分享
    poster(val) {
      this.postval = val
      this.status = true
      this.$nextTick(() => {
        this.$refs.posterShareref.create_ewm()
      })
    },
    // 切换标签
    tabClick() {
      this.tableData = []
      this.formData.page = 1
      this.formData.status = this.activeName
      this.getTableList()
    },
    // 作废兑换码记录
    voidDHM(redeem_code_id) {
      this.$confirm('作废后，该兑换码将为无效码，不可恢复！', '作废兑换码', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$http({
          url: '/Redeem/cancelRedeemCode',
          data: { redeem_code_id, redeem_id: this.$route.query.redeem_id },
          callback: () => {
            this.getTableList()
            this.$message({
              type: 'success',
              message: '作废成功!',
            })
          },
        })
      })
    },
    // 筛选表格数据
    changeForm() {
      this.formData.page = 1
      this.getTableList()
    },
    // 分页
    pageCodeChange(val) {
      this.formData.page = val
      this.getTableList()
    },
    // 复制兑换码弹窗
    send(code) {
      this.dialogIsShow_1 = true
      this.$http({
        url: '/Redeem/getPlayUrl',
        callback: (res) => {
          this.copyUrl =
            '您的专属课程已经生成，快来领取兑换码，轻松学习美滋滋。点击领取：  ' +
            res.data +
            code
        },
      })
    },
    //复制
    copy() {
      var that = this
      var clipboard = new Clipboard('.info_right')
      clipboard.on('success', () => {
        that.$root.prompt({
          msg: '复制成功',
          type: 'success',
        })
        //这里你如果引入了elementui的提示就可以用，没有就注释即可
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        // 不支持复制
        that.$root.prompt('该浏览器不支持自动复制')
        // 释放内存
        clipboard.destroy()
      })
    },
    // 获取表格数据
    getTableList() {
      this.isLoading = true
      this.$http({
        url: '/Redeem/codeDetail',
        data: this.formData,
        callback: ({ data, allNum, is_adder }) => {
          this.isLoading = false
          this.total = Number(allNum)
          this.tableData = data
          this.is_adder = is_adder
        },
        error: (err) => {
          this.isLoading = false
          if (err.code == 204) {
            this.total = 0
            this.tableData = []
          }
        },
      })
    },
  },
}
</script>
<style lang="less" scoped>
#editRedeemCode {
  background: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  .student { 
    display: flex;
    align-items: center;
    .student_name {
      font-size: 12px;
      font-weight: 500;
      color: #333333;
      line-height: 17px;
      color: blue !important;
      display: flex;
      flex-direction: column;
    }
  }
  .el-tabs {
    /deep/ .el-tabs__content {
      .header {
        width: 100%;
        overflow: hidden;
        margin-bottom: 10px;
        .el-input {
          .el-input-group__prepend {
            background-color: #fff;
          }
        }
        .Percentage {
          width: 230px;
          display: inline-block;
          height: 100%;
          margin-left: 20px;
          color: rgba(10, 162, 155, 1);
          background: ren;
          font-size: 14px;
          p {
            height: 17px;
            line-height: 17px;
            margin-bottom: 14px;
          }
        }
      }
      .el-divider {
        background: rgba(27, 157, 151, 1);
        &.divider_color {
          background-color: #cccccc !important;
        }
      }
    }
  }
  .send_verification {
    /deep/ .el-dialog {
      .el-dialog__body {
        .box_info {
          height: 130px;
          margin-bottom: 20px;
          .left {
            width: 93px;
            height: 100px;
            float: left;
          }
          .right {
            width: 100%;
            // width: calc(100% - 93px);
            float: right;
            height: 100px;
            background: rgba(245, 245, 245, 1);
            box-sizing: border-box;
            padding: 17px 20px;
            font-size: 12px;
            .info_left {
              width: 500px;
              height: 100%;
              float: left;
            }
            .info_right {
              width: 100px;
              float: right;
              color: rgba(27, 157, 151, 1);
              cursor: pointer;
            }
          }
          .tip {
            text-indent: 110px;
            color: rgba(255, 53, 53, 1);
            font-size: 12px;
            height: 30px;
            line-height: 30px;
          }
        }
      }
    }
  }
}
</style>
